import { render, staticRenderFns } from "./editQuestion.vue?vue&type=template&id=cabb5cbc&scoped=true&"
import script from "./editQuestion.vue?vue&type=script&lang=js&"
export * from "./editQuestion.vue?vue&type=script&lang=js&"
import style0 from "./editQuestion.vue?vue&type=style&index=0&id=cabb5cbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cabb5cbc",
  null
  
)

export default component.exports